<template>
    <nav class="footer__menu" aria-label="secondary-navigation">
        <div class="footer__menu-item">
            <a
                href="/polityka-prywatnosci"
                target="_blank"
                class="footer__menu-link"
                >Polityka prywatności</a
            >
        </div>
        <div class="footer__menu-item">
            <a
                href="/Regulamin loterii_Łaciate_FINAL.pdf"
                target="_blank"
                class="footer__menu-link"
                >Regulamin</a
            >
        </div>
    </nav>
</template>
