<template>
    <form id="" action="" @submit.prevent="sendForm" class="form" novalidate>
        <div class="row gx-3 gx-md-4 align-items-start">
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-form__span">Imię</span>
                    <input
                        class="form__input text-uppercase"
                        v-model="firstName"
                        placeholder="Imię"
                    />
                    <div class="form__error" v-if="errors['first_name']">
                        {{ errors["first_name"][0] }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-form__span">Nazwisko</span>
                    <input
                        class="form__input text-uppercase"
                        v-model="lastName"
                        placeholder="Nazwisko"
                    />
                    <div class="form__error" v-if="errors['last_name']">
                        {{ errors["last_name"][0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row gx-3 gx-md-4 align-items-start">
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-form__span"
                        >Adres e-mail</span
                    >
                    <input
                        class="form__input text-uppercase"
                        v-model="email"
                        placeholder="E-mail"
                    />
                    <div class="form__error" v-if="errors['email']">
                        {{ errors["email"][0] }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-form__span"
                        >Nr telefonu</span
                    >
                    <input
                        class="form__input text-uppercase"
                        v-model="phone"
                        placeholder="Nr telefonu"
                    />
                    <div class="form__error" v-if="errors['phone']">
                        {{ errors["phone"][0] }}
                    </div>
                </div>
            </div>
        </div>
        <label
            class="form__label form__label--checkbox mt-4 mb-0 mt-sm-5 mb-sm-3"
        >
            <input
                v-model="invoice"
                class="form__input form__input--checkbox"
                type="checkbox"
            />
            <span class="form__checkmark form__checkmark--blue"></span>
            <div class="heading heading--h6 heading--black">
                Mam fakturę imienną zamiast paragonu fiskalengo
            </div>
        </label>
        <label class="form__label form__label--checkbox mt-4 mb-2 mb-sm-4">
            <input
                v-model="e_receipt"
                class="form__input form__input--checkbox"
                type="checkbox"
            />
            <span class="form__checkmark form__checkmark--blue"></span>
            <div class="heading heading--h6 heading--black">
                Mam e-paragon zamiast paragonu fiskalengo
            </div>
        </label>
        <div class="row gx-3 gx-md-4 align-items-start">
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-form__span"
                        >Data dokonania zakupu</span
                    >
                    <div class="form__input-popup-icon">
                        <Datepicker
                            ref="datepicker"
                            v-model="saleDate"
                            textInput
                            :textInputOptions="{
                                format: 'dd.MM.yyyy',
                                enterSubmit: true,
                                tabSubmit: true,
                            }"
                            :minDate="new Date('2024-04-02')"
                            :maxDate="new Date('2024-05-27')"
                            format="dd.MM.yyyy"
                            :enableTimePicker="false"
                            inputClassName="form__input text-uppercase form__input--datepicker"
                            selectText="Wybierz"
                            cancelText="Anuluj"
                            locale="pl"
                            placeholder="DD.MM.YYYY"
                            @blur="blur"
                        >
                        </Datepicker>

                        <form-modal v-if="!invoice && !e_receipt">
                            <img src="@/assets/PARAGON_datasprzedazy.jpg" />
                        </form-modal>

                        <form-modal v-if="e_receipt">
                            <img
                                src="@/assets/E_PARAGON_data_sprzedazy.jpg"
                                width="250"
                            />
                        </form-modal>
                    </div>
                    <div class="form__error" v-if="errors['sale_date']">
                        {{ errors["sale_date"][0] }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm">
                <div class="form__group" v-if="invoice">
                    <span class="form__span section-form__span"
                        >Nr faktury imiennej</span
                    >
                    <input
                        class="form__input text-uppercase"
                        v-model="invoiceID"
                        placeholder="Nr faktury imiennej"
                    />
                    <div class="form__error" v-if="errors['invoice_id']">
                        {{ errors["invoice_id"][0] }}
                    </div>
                </div>
                <div class="form__group" v-if="e_receipt">
                    <span class="form__span section-form__span"
                        >Nr e-paragonu (nr lub nr transakcji)</span
                    >
                    <div class="form__input-popup-icon">
                        <input
                            class="form__input text-uppercase"
                            v-model="receiptID"
                            placeholder="Nr e-paragonu"
                        />
                        <form-modal>
                            <img
                                src="@/assets/E_PARAGON_nrparagonu.png"
                                width="250"
                            />
                        </form-modal>
                    </div>
                    <div class="form__error" v-if="errors['receipt_id']">
                        {{ errors["receipt_id"][0] }}
                    </div>
                </div>
                <div class="form__group" v-if="!invoice && !e_receipt">
                    <span class="form__span section-form__span"
                        >Nr paragonu fiskalnego</span
                    >
                    <div class="form__input-popup-icon">
                        <input
                            class="form__input text-uppercase"
                            v-model="receiptID"
                            placeholder="Nr paragonu fiskalnego"
                        />
                        <form-modal>
                            <img src="@/assets/PARAGON_nrparagonu.jpg" />
                        </form-modal>
                    </div>
                    <div class="form__error" v-if="errors['receipt_id']">
                        {{ errors["receipt_id"][0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row gx-3 gx-md-4 align-items-start">
            <div class="col-12 col-sm-6">
                <div class="row gx-3 gx-md-4 align-items-end">
                    <div class="col-12 col-sm-8">
                        <div class="form__group" v-if="!invoice && !e_receipt">
                            <span class="form__span section-form__span"
                                >Nr kasy fiskalnej</span
                            >
                            <div class="form__input-popup-icon">
                                <input
                                    class="form__input text-uppercase"
                                    v-model="cashRegisterID"
                                    placeholder="Nr kasy fiskalnej"
                                />
                                <form-modal>
                                    <img
                                        src="@/assets/PARAGON_kasafiskalna.jpg"
                                    />
                                </form-modal>
                            </div>
                            <div
                                class="form__error"
                                v-if="errors['cash_register_id']"
                            >
                                {{ errors["cash_register_id"][0] }}
                            </div>
                        </div>
                        <div class="form__group" v-if="e_receipt">
                            <span class="form__span section-form__span"
                                >Godzina dokonania zakupu</span
                            >
                            <div class="form__input-popup-icon">
                                <input
                                    type="time"
                                    class="form__input text-uppercase"
                                    v-model="saleTime"
                                    placeholder="00:00"
                                />
                                <form-modal>
                                    <img
                                        src="@/assets/E_PARAGON_godzina_sprzedazy.jpg"
                                        width="250"
                                    />
                                </form-modal>
                            </div>
                            <div class="form__error" v-if="errors['sale_time']">
                                {{ errors["sale_time"][0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form__group">
                            <span class="form__span section-form__span"
                                >Liczba produktów</span
                            >
                            <div class="form__group-append">
                                <input
                                    class="form__input"
                                    min="1"
                                    max="12"
                                    type="number"
                                    :value="numberOfCodes"
                                    @input="val"
                                />
                                <button
                                    class="form__button-plus"
                                    @click.prevent="valUp"
                                ></button>
                                <button
                                    class="form__button-minus"
                                    @click.prevent="valDown"
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row gx-3 gx-md-4">
                    <div class="col-12">
                        <div class="form__group">
                            <span
                                class="form__span section-form__span d-flex justify-content-between"
                                ><span
                                    ><br />Kod promocyjny (13 cyfr)</span
                                ></span
                            >

                            <div
                                class="w-100"
                                :class="{ 'mt-3 mt-sm-4': k }"
                                v-for="(input, k) in codes"
                                :key="k"
                            >
                                <div class="d-flex w-100">
                                    <div class="position-relative w-100">
                                        <input
                                            class="form__input"
                                            v-model="codes[k]"
                                            @input="
                                                validateCode(
                                                    $event.target.value,
                                                    k,
                                                )
                                            "
                                            placeholder="LATXXXXXXXXXX"
                                        />
                                        <form-modal v-if="k == 0">
                                            <img
                                                src="@/assets/unikalny-kod.png"
                                                width="340"
                                            />
                                        </form-modal>
                                    </div>
                                </div>
                                <div
                                    class="form__error"
                                    v-if="errors['codes.' + k]"
                                >
                                    {{ errors["codes." + k][0] }}
                                </div>
                            </div>
                            <div class="form__error" v-if="errors['codes']">
                                {{ errors["codes"][0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p
            class="section-form__paragraph text-center mt-4 mb-2 mt-sm-5 mb-sm-5"
        >
            <a
                href="/Kluzula informacyjne_Loteria na prostych zasadach.pdf"
                target="_blank"
                >Treść klauzuli informacyjnej dotyczącej przetwarzania danych
                osobowych</a
            >
        </p>
        <label class="form__label form__label--checkbox mb-sm-5">
            <input
                class="form__input form__input--checkbox"
                type="checkbox"
                v-model="allSelected"
            />
            <span class="form__checkmark form__checkmark--blue"></span>
            <p><strong>Zaznacz wszystkie oświadczenia i zgody.</strong></p>
        </label>
        <label
            class="form__label form__label--checkbox"
            v-for="term of terms"
            :key="term.id"
        >
            <input
                type="checkbox"
                v-model="term.checked"
                class="form__input form__input--checkbox"
            />
            <span
                class="form__checkmark form__checkmark--blue"
                :class="{
                    'form__checkmark--error':
                        termsErrors.includes(term.id) && !term.checked,
                }"
            ></span>
            <p v-if="!readMoreActivated[term.id]">
                {{ term.description.slice(0, 196) }}
                <span
                    v-if="
                        !readMoreActivated[term.id] &&
                        term.description.length - 1 > 196
                    "
                    class="form__checkmark-expand"
                    @click.prevent="checkmarkExpand(term.id)"
                    >[rozwiń]</span
                >
            </p>
            <p v-if="readMoreActivated[term.id]">
                {{ term.description }}
                <span
                    class="form__checkmark-collapse"
                    v-if="readMoreActivated[term.id]"
                    @click.prevent="checkmarkCollapse(term.id)"
                    >[zwiń]</span
                >
            </p>
        </label>
        <div class="form__error-terms" v-if="termsErrors.length > 0">
            Zaznaczone zgody są wymagane.
        </div>
        <div class="form__error-terms" v-if="errors.grecaptcha_token">
            Błąd walidacji recaptcha.
        </div>
        <div class="form__error-terms" v-if="errors['no_lottery']">
            {{ errors["no_lottery"][0] }}
        </div>
        <button
            type="submit"
            :disabled="submitting"
            class="btn button section-form__button"
            title="Wyślij"
        >
            Wyślij
        </button>
        <send-form-modal ref="modal">
            <div>
                <p>
                    Dziękujemy.
                    <br />Twoje zgłoszenie zostało zarejestrowane do loterii
                    <br />„Loteria na prostych zasadach”
                </p>
                <p>Życzymy powodzenia w najbliższym losowaniu.</p>
            </div>
        </send-form-modal>
        <send-form-modal ref="errors">
            <div>
                <h5>Popraw formularz.</h5>
                <p class="mx-md-5">
                    <br />
                    W formularzu wprowadzono niepoprawne dane.<br />
                    Sprawdź poprawność danych i wyślij ponownie zgłoszenie.
                </p>
            </div>
        </send-form-modal>
        <send-form-modal ref="requestError">
            <div>
                <h5>Wystąpił błąd.</h5>
                <p class="mx-md-5">
                    <br />
                    Wystąpił błąd podczas wysyłania zgłoszenia.<br />
                    Spróbuj ponownie.
                </p>
            </div>
        </send-form-modal>
    </form>
</template>

<script>
import axios from "axios";
import FormModal from "@/components/parts/FormModal";
import SendFormModal from "@/components/parts/SendFormModal";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

const PurchaseProofType = {
    Receipt: 0,
    Invoice: 1,
    E_Receipt: 2,
};

export default {
    data() {
        return {
            cashRegisterID: "",
            firstName: "",
            lastName: "",
            email: "",
            invoice: false,
            e_receipt: false,
            phone: "",
            codes: ["LAT"],
            receiptID: "",
            invoiceID: "",
            saleDate: "",
            saleTime: "",
            readMoreActivated: {},
            terms: [
                {
                    id: "1",
                    description:
                        "Zapoznałem/Zapoznałam się i akceptuję Regulamin loterii promocyjnej „Loteria na prostych zasadach” i akceptuję jego postanowienia.",
                    checked: false,
                    required: true,
                },
                {
                    id: "2",
                    description: "Oświadczam, że jestem osobą pełnoletnią.",
                    checked: false,
                    required: true,
                },
                {
                    id: "3",
                    description:
                        "Oświadczam, że nie jestem pracownikiem Organizatora loterii promocyjnej „Loteria na prostych zasadach” ani innych firm wskazanych w punkcie 1.7 Regulaminu loterii.",
                    checked: false,
                    required: true,
                },
                {
                    id: "4",
                    description:
                        "Wyrażam zgodę na przetwarzanie przez Organizatora loterii promocyjnej „Loteria na prostych zasadach”, tj. GPD Agency Sp. z o.o. Sp. k. z siedzibą w Poznaniu (60-829) przy ul. Roosevelta 18, moich danych osobowych, podanych przeze mnie w Formularzu Zgłoszeniowym, w celach związanych z organizacją i przeprowadzeniem ww. loterii promocyjnej, w szczególności w celu wydania nagród i rozpatrzenia ewentualnych reklamacji przez Organizatora.",
                    checked: false,
                    required: true,
                },
            ],
            selected: [],
            allSelected: false,
            errors: [],
            termsErrors: [],
            submitting: false,
        };
    },
    components: {
        FormModal,
        Datepicker,
        SendFormModal,
    },
    methods: {
        blur() {
            this.$refs["datepicker"].selectDate();
            if (!this.saleDate) {
                this.$refs["datepicker"].clearValue();
            }
        },
        formatDateToYMD(value) {
            const date = new Date(value);
            if (date instanceof Date && !isNaN(date.valueOf()))
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            return null;
        },
        changeNumberOfCodes(newValue) {
            this.codes.splice(
                newValue,
                this.numberOfCodes - newValue,
                ...Array(Math.max(0, newValue - this.numberOfCodes)).fill(
                    "LAT",
                ),
            );
        },
        val(event) {
            if (isNaN(parseInt(event.target.value))) return;
            this.changeNumberOfCodes(
                Math.max(1, Math.min(12, +event.target.value)),
            );
        },
        valUp() {
            if (this.numberOfCodes < 12) {
                this.changeNumberOfCodes(this.numberOfCodes + 1);
            }
        },
        valDown() {
            if (this.numberOfCodes > 1) {
                this.changeNumberOfCodes(this.numberOfCodes - 1);
            }
        },
        termsValidate() {
            this.termsErrors = [];
            this.terms.forEach((item) => {
                if (item.required && !item.checked) {
                    this.termsErrors.push(item.id);
                }
            });
        },
        validateCode(value, index) {
            if (value.length == 1) {
                this.codes[index] = "LAT" + value;
            } else {
                this.codes[index] = "LAT" + value.slice(3);
            }
            if (value.length > 16) {
                this.codes[index] = value.slice(0, 16);
            }
        },
        async sendForm() {
            this.submitting = true;
            this.errors = [];

            this.termsValidate();

            if (this.termsErrors.length > 0) {
                this.submitting = false;
                return;
            }

            try {
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha("form");
                const resp = await axios.post(
                    `${process.env.VUE_APP_API_URL}/applications`,
                    {
                        cash_register_id: this.cashRegisterID,
                        codes: this.codes,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        purchase_proof_type: this.invoice
                            ? PurchaseProofType.Invoice
                            : this.e_receipt
                              ? PurchaseProofType.E_Receipt
                              : PurchaseProofType.Receipt,
                        phone: this.phone,
                        receipt_id: this.receiptID,
                        sale_date: this.formatDateToYMD(this.saleDate),
                        sale_time: this.saleTime,
                        invoice_id: this.invoiceID,
                        grecaptcha_token: token,
                    },
                );

                if (resp.status >= 400) {
                    this.$refs.requestError.showModal = true;
                    return;
                }

                this.$gtm.trackEvent({
                    event: "formSubmitC",
                    OrderID: resp.data.random_id,
                });
                this.codes.forEach((code) => {
                    this.$gtm.trackEvent({
                        event: "formData",
                        OrderID: resp.data.random_id,
                        codeID: code,
                    });
                });

                this.$refs.modal.showModal = true;

                this.$router.push({
                    name: "thankyou",
                    params: { orderId: resp.data.random_id },
                });
            } catch (error) {
                if (
                    error.response?.data?.errors &&
                    Object.entries(error.response.data.errors).length > 0
                ) {
                    this.errors = error.response.data.errors;
                    this.$refs.errors.showModal = true;
                } else {
                    this.$refs.requestError.showModal = true;
                }
            }

            this.submitting = false;
        },
        checkmarkExpand(id) {
            this.readMoreActivated[id] = true;
        },
        checkmarkCollapse(id) {
            this.readMoreActivated[id] = false;
        },
    },
    computed: {
        numberOfCodes() {
            return this.codes.length;
        },
    },
    watch: {
        allSelected() {
            if (this.allSelected) {
                this.terms.map((u) => (u.checked = true));
            } else {
                this.terms.map((u) => (u.checked = false));
            }
        },
        phone(newVal) {
            this.phone = newVal
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
            if (newVal.length > 9) {
                this.phone = this.phone.slice(0, 9);
            }
        },
        invoice(newVal) {
            if (newVal && this.e_receipt) this.e_receipt = false;
        },
        e_receipt(newVal) {
            if (newVal && this.invoice) this.invoice = false;
        },
    },
};
</script>

<style>
input[type="date" i] {
    flex-direction: row-reverse;
    padding-left: 0px;
}
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
</style>
