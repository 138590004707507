<template>
    <header class="header">
        <svg class="header__svg">
            <clipPath id="clip-header" clipPathUnits="objectBoundingBox">
                <path
                    d="M0.287,0.952 C0.118,0.901,0,0.492,0,0.492 L0,-0.004,0.999,-0.042 l0,0.65 C0.629,0.468,0.523,1,0.287,0.952"
                ></path>
            </clipPath>
        </svg>
        <svg class="header__svg">
            <clipPath id="clip-header-mobile" clipPathUnits="objectBoundingBox">
                <path d="M1,0.462 C0.362,0.307,0.407,1,0,0.994 L0,0 H1"></path>
            </clipPath>
        </svg>
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-sm-3"><SiteLogo /></div>
                <div class="col-sm-9"><HeaderNavbar /></div>
            </div>
        </div>
    </header>
    <div class="hamburger js-hamburger" @click="toggle">
        <div class="hamburger__lines"></div>
    </div>
</template>

<script>
import SiteLogo from "@/components/SiteLogo";
import HeaderNavbar from "@/components/navbars/HeaderNavbar";

export default {
    name: "App",
    components: {
        SiteLogo,
        HeaderNavbar,
    },
    methods: {
        toggle() {
            document
                .querySelector(".js-menu-toggle")
                .classList.toggle("menu--active");
            document
                .querySelector(".js-hamburger")
                .classList.toggle("hamburger--active");
        },
    },
    computed: {},
};
</script>
