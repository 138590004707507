<template>
    <section-layout class="section-dummy section-dummy--thankyou flex">
        <span class="section-dummy__path-1"></span>
        <span class="section-dummy__path-2"></span>
        <span class="section-dummy__path-mobile-1"></span>
        <span class="section-dummy__path-mobile-2"></span>
        <span class="section-dummy__path-mobile-3"></span>
        <div class="section-dummy__content">
            <img
                src="@/assets/logo-laciata-loteria.svg"
                class="section-dummy__content-img"
                alt=""
            />
        </div>

        <h1 class="section-dummy__heading heading heading--h4 heading--black">
            Dziękujemy.
        </h1>
        <p class="section-dummy__paragraph">
            Twoje zgłoszenie zostało zarejestrowane do loterii
            <span class="red">„Loteria na prostych zasadach”</span>.
        </p>
        <br />

        <p class="section-dummy__paragraph">Życzymy powodzenia w losowaniu.</p>
        <br />

        <p class="section-dummy__paragraph">
            <a href="/" class="btn button">Wróć na stronę główną</a>
        </p>
        <div class="mt-md-3 mt-sm-4 mt-2 d-flex justify-content-center">
            <footer-navbar />
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
import FooterNavbar from "@/components/navbars/FooterNavbar.vue";
export default {
    components: {
        SectionLayout,
        FooterNavbar,
    },
};
</script>
