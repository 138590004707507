import { createRouter, createWebHistory } from "vue-router";
import PageHome from "../pages/PageHome";
import PageWinnerForm from "../pages/PageWinnerForm";
import PageThankYou from "../pages/PageThankYou";
import PagePrivacyPolicy from "../pages/PagePrivacyPolicy";
import PageDummy from "@/pages/PageDummy.vue";

const lotteryStarted =
    new Date().valueOf() >
    new Date(process.env.VUE_APP_LOTTERY_START_DATE).valueOf();

const routes = lotteryStarted
    ? [
          {
              path: "/",
              name: "home",
              component: PageHome,
          },
          {
              path: "/polityka-prywatnosci",
              name: "privacy-policy",
              component: PagePrivacyPolicy,
          },
          {
              path: "/uzupelnij-dane/:id",
              name: "winner",
              component: PageWinnerForm,
          },
          {
              path: "/dziekujemy",
              name: "thankyou",
              component: PageThankYou,
              props: true,
              // beforeEnter: (to, from, next) => {
              //     if (to.params.orderId) {
              //         next(); // <-- everything good, proceed
              //     } else {
              //         next({ name: "home" }); // <-- redirect to setup
              //     }
              // },
          },
      ]
    : [
          {
              path: "/",
              name: "home",
              component: PageDummy,
          },
          {
              path: "/polityka-prywatnosci",
              name: "privacy-policy",
              component: PagePrivacyPolicy,
          },
      ];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            if (window.innerWidth < 1025) {
                return {
                    el: to.hash,
                    top: 100,
                };
            } else {
                return {
                    el: to.hash,
                    top: 255,
                };
            }
        } else {
            return { top: 0 };
        }
    },
});

export default router;
