<template>
    <section-layout class="section-contact" id="kontakt">
        <div class="patch patch--home-8"></div>
        <div class="patch patch--home-9"></div>
        <div class="heading heading--h2 text-center">Kontakt</div>
        <div class="row justify-content-center">
            <div class="col-10 col-md-8">
                <ContactForm />
            </div>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
import ContactForm from "@/components/forms/ContactForm";

export default {
    components: {
        SectionLayout,
        ContactForm,
    },
};
</script>
