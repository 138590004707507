<template>
    <section-layout class="section-winner-form" id="zwyciezcy">
        <WinnerForm />
    </section-layout>
</template>
<script>
import SectionLayout from "@/layouts/SectionLayout";
import WinnerForm from "@/components/forms/WinnerForm";

export default {
    components: {
        SectionLayout,
        WinnerForm,
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
};
</script>
