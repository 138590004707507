<template>
    <main-layout>
        <section-privacy-policy />
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import SectionPrivacyPolicy from "@/components/sections/SectionPrivacyPolicy.vue";

export default {
    components: {
        MainLayout,
        SectionPrivacyPolicy,
    },
};
</script>
