<template>
    <div class="form__popup">
        <div class="form__popup-icon" @click="showModal = !showModal"></div>
        <Transition name="fade" appear>
            <div
                ref="modal"
                class="form__popup-content form__popup-content--absolute"
                v-if="showModal"
            >
                <div
                    class="form__popup-icon-close"
                    @click="showModal = false"
                ></div>
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: "FormModal",
    data() {
        return {
            showModal: false,
        };
    },
};
</script>
