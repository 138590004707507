<template>
    <dummy-layout>
        <section-layout class="section-dummy flex">
            <span class="section-dummy__path-1"></span>
            <span class="section-dummy__path-2"></span>
            <span class="section-dummy__path-mobile-1"></span>
            <span class="section-dummy__path-mobile-2"></span>
            <span class="section-dummy__path-mobile-3"></span>
            <div class="section-dummy__content">
                <img
                    src="@/assets/logo-laciata-loteria.svg"
                    class="section-dummy__content-img"
                    alt=""
                />
            </div>
            <h1
                class="section-dummy__heading heading heading--h4 heading--blue"
            >
                LOTERIA rozpocznie&nbsp;się 2&nbsp;KWIETNIA 2024&nbsp;roku.
            </h1>
            <p class="section-dummy__paragraph">
                Zakupu i zgłoszenia do loterii należy dokonać
                od&nbsp;2&nbsp;kwietnia do&nbsp;27&nbsp;maja 2024 roku.
            </p>
            <div class="mt-md-3 mt-sm-4 mt-2 d-flex justify-content-center">
                <footer-navbar />
            </div>
        </section-layout>
    </dummy-layout>
</template>

<script>
import DummyLayout from "../layouts/DummyLayout.vue";
import SectionLayout from "../layouts/SectionLayout.vue";
import FooterNavbar from "../components/navbars/FooterNavbar.vue";
export default {
    data() {
        return {
            isDummy: "class",
        };
    },
    components: {
        DummyLayout,
        SectionLayout,
        FooterNavbar,
    },
};
</script>
