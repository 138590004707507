<template>
    <form form id="" action="" @submit.prevent="sendForm" class="form">
        <div class="row">
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-contact__span"
                        >Imię i Nazwisko</span
                    >
                    <input
                        class="form__input form__input--border-black"
                        placeholder="Imię i Nazwisko"
                        v-model="form.name"
                    />
                    <div class="form__error" v-if="errors['name']">
                        {{ errors["name"][0] }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-contact__span"
                        >Adres e-mail</span
                    >
                    <input
                        class="form__input form__input--border-black"
                        v-model="form.email"
                        placeholder="e-mail"
                    />
                    <div class="form__error" v-if="errors['email']">
                        {{ errors["email"][0] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm">
                <div class="form__group">
                    <span class="form__span section-contact__span">Treść</span>
                    <textarea
                        v-model="form.content"
                        class="form__textarea form__textarea--border-black"
                        rows="7"
                        placeholder="Treść..."
                    />
                    <div class="form__error" v-if="errors['content']">
                        {{ errors["content"][0] }}
                    </div>
                </div>
            </div>
        </div>
        <label
            class="form__label form__label--checkbox mt-4"
            v-for="contactTerm of contactTerms"
            :key="contactTerm.id"
        >
            <input
                class="form__input form__input--checkbox"
                type="checkbox"
                v-model="contactTerm.checked"
            />
            <span
                class="form__checkmark form__checkmark--black"
                :class="{
                    'form__checkmark--error':
                        termsErrors.includes(contactTerm.id) &&
                        !contactTerm.checked,
                }"
            ></span>
            <p v-if="!readMoreActivated[contactTerm.id]">
                {{ contactTerm.description.slice(0, 195) }}
                <span
                    v-if="
                        !readMoreActivated[contactTerm.id] &&
                        contactTerm.description.length - 1 > 195
                    "
                    class="form__checkmark-expand"
                    @click.prevent="checkmarkExpand([contactTerm.id])"
                    >[rozwiń]</span
                >
            </p>
            <p v-if="readMoreActivated[contactTerm.id]">
                {{ contactTerm.description }}
                <span
                    class="form__checkmark-collapse"
                    v-if="readMoreActivated[contactTerm.id]"
                    @click.prevent="checkmarkCollapse([contactTerm.id])"
                    >[zwiń]</span
                >
            </p>
        </label>
        <div class="form__error-terms" v-if="termsErrors.length > 0">
            Zaznaczone zgody są wymagane.
        </div>
        <div class="form__error-terms" v-if="errors.grecaptcha_token">
            Błąd walidacji recaptcha.
        </div>
        <div class="text-end">
            <button
                type="submit"
                :disabled="this.submitting"
                class="btn button button--blue"
                title="Wyślij"
            >
                Wyślij
            </button>
        </div>
        <send-form-modal ref="modal">
            <div>
                <p>
                    Dziękujemy.
                    <br />Twoja wiadomość została wysłana.
                </p>
                <p>
                    <img
                        class="form__popup-content-image"
                        src="@/assets/laciate-aics.svg"
                    />
                </p>
            </div>
        </send-form-modal>
    </form>
</template>

<script>
import SendFormModal from "@/components/parts/SendFormModal";
import axios from "axios";

export default {
    components: {
        SendFormModal,
    },
    data() {
        return {
            contactTerms: [
                {
                    id: "1",
                    description:
                        "Przyjmuję do wiadomości, że administratorem danych osobowych, podanych przeze mnie w formularzu kontaktowym jest GPD Agency Sp. z o.o. Sp. k z siedzibą w Poznaniu (60-829) przy ul. Roosevelta 18. Podstawą przetwarzania podanych danych na potrzeby udzielenia odpowiedzi na złożone zapytanie w ramach skrzynki kontaktowej dostępnej na stronie loterii promocyjnej jest prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO), polegający na udzieleniu odpowiedzi na złożone zapytanie. Podanie przeze mnie danych osobowych jest dobrowolne, ale konieczne do uzyskania odpowiedzi na pytanie wysłane przeze mnie za pomocą formularza kontaktowego. Administrator wyznaczył inspektora ochrony danych, z którym można się skontaktować pod adresem administratora oraz e-mailowo pod adresem ochrona.danych@gpd.com.pl. Podane przeze mnie dane będą przetwarzane wyłącznie w czasie niezbędnym do udzielenia odpowiedzi na złożone zapytanie w ramach skrzynki kontaktowej. Odbiorcami moich danych osobowych mogą być podmioty świadczące usługi hostingu, informatyczne, prawne lub księgowe, a także Spółdzielnia Mleczarska „Mlekpol” z siedziba w Grajewie. Przysługuje mi prawo dostępu do podanych danych oraz otrzymania ich kopii, prawo żądania ich sprostowania, poprawiania, usunięcia, ograniczenia ich przetwarzania, prawo wniesienia sprzeciwu wobec ich przetwarzania, a także prawo wniesienia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych.",
                    checked: false,
                    required: true,
                },
            ],
            form: {},
            readMoreActivated: {},
            termsErrors: [],
            errors: [],
            submitting: false,
        };
    },
    methods: {
        termsValidate() {
            this.termsErrors = [];
            this.contactTerms.forEach((item) => {
                if (item.required && !item.checked) {
                    this.termsErrors.push(item.id);
                }
            });
        },
        async sendForm() {
            this.submitting = true;
            this.termsValidate();

            if (this.termsErrors.length > 0) {
                this.submitting = false;
                return;
            }
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("form");
            axios
                .post(`${process.env.VUE_APP_API_URL}/contact-form`, {
                    ...this.form,
                    grecaptcha_token: token,
                })
                .then(() => {
                    this.$refs.modal.showModal = true;
                    this.submitting = false;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.errors = error.response.data.errors;
                    this.submitting = false;
                });
        },
        checkmarkExpand(id) {
            this.readMoreActivated[id] = true;
        },
        checkmarkCollapse(id) {
            this.readMoreActivated[id] = false;
        },
    },
};
</script>
