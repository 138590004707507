<template>
    <section-layout class="section-rewards" id="nagrody">
        <div class="patch patch--home-1"></div>
        <div class="patch patch--mobile patch--mobile-1"></div>
        <div class="patch patch--mobile patch--mobile-2"></div>
        <div class="patch patch--mobile patch--mobile-3"></div>
        <div class="section-rewards__container">
            <div class="heading heading--h2 text-center">Nagrody</div>
            <div class="section-rewards__wrapper">
                <img src="@/assets/nagrody.png" class="d-none d-sm-block" />
                <img
                    src="@/assets/nagrody-mobile.png"
                    class="m-auto d-block d-sm-none"
                />
            </div>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
export default {
    components: {
        SectionLayout,
    },
};
</script>
