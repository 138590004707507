<template>
    <main class="main">
        <slot></slot>
    </main>
</template>

<script>
export default {
    name: "App",
    components: {},
    computed: {},
};
</script>
