<template>
    <main-layout>
        <SectionWinnerForm />
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

// Sections

import SectionWinnerForm from "@/components/sections/SectionWinnerForm";

export default {
    components: {
        MainLayout,
        SectionWinnerForm,
    },
};
</script>
