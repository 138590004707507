<template>
    <section-layout class="section-privacy-policy">
        <div v-html="html"></div>
        <div class="mt-5" ref="script-container"></div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
import html from "@/assets/Polityka prywatności_Loteria na prostych zas adach.html";

export default {
    mounted() {
        const cookiebotDeclaratonScript = document.createElement("script");
        cookiebotDeclaratonScript.setAttribute(
            "src",
            "https://consent.cookiebot.com/0c38b9fd-6184-485a-bfa5-51ad8decaf2c/cd.js",
        );
        this.$refs["script-container"].appendChild(cookiebotDeclaratonScript);
    },
    data() {
        return {
            html,
        };
    },
    components: {
        SectionLayout,
    },
};
</script>
