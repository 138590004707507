<template>
    <main-layout>
        <SectionBanner />
        <SectionRewards />
        <SectionHowTo />
        <SectionProducts />
        <SectionLotteryForm v-if="loteryActive" />
        <SectionWinners />
        <SectionContact />
        <!--ClickMeter.com page views tracking: Display RMRKT CPC -->
        <component :is="'script'" id="" type="text/javascript">
            var ClickMeter_pixel_url = "//pixel.watch/mgpx";
        </component>
        <component
            :is="'script'"
            type="text/javascript"
            id="cmpixelscript"
            src="//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js"
        ></component>
        <noscript>
            <img height="0" width="0" alt="" src="http://pixel.watch/mgpx" />
        </noscript>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

// Sections

import SectionBanner from "@/components/sections/SectionBanner";
import SectionRewards from "@/components/sections/SectionRewards";
import SectionHowTo from "@/components/sections/SectionHowTo";
import SectionProducts from "@/components/sections/SectionProducts";
import SectionLotteryForm from "@/components/sections/SectionLotteryForm";
import SectionWinners from "@/components/sections/SectionWinners";
import SectionContact from "@/components/sections/SectionContact";

export default {
    components: {
        MainLayout,
        SectionBanner,
        SectionRewards,
        SectionHowTo,
        SectionProducts,
        SectionLotteryForm,
        SectionWinners,
        SectionContact,
    },
    computed: {
        loteryActive() {
            return this.$store.state.loteryActive;
        },
    },
};
</script>
