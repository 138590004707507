import { createStore } from "vuex";

export default createStore({
    state: {
        loteryActive: true,
    },
    getters: {},
    mutations: {
        turnOffLottery(state) {
            state.loteryActive = false;
        },
    },
    actions: {},
    modules: {},
});
