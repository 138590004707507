<template>
    <nav class="menu js-menu-toggle" aria-label="primary-navigation">
        <div class="menu__item">
            <router-link to="./#nagrody" @click="remove" class="menu__link"
                >Nagrody</router-link
            >
        </div>
        <div class="menu__item">
            <router-link to="./#jakgrac" @click="remove" class="menu__link"
                >Jak grać</router-link
            >
        </div>
        <div class="menu__item">
            <router-link to="./#produkty" @click="remove" class="menu__link"
                >Produkty promocyjne</router-link
            >
        </div>
        <div class="menu__item" v-if="loteryActive">
            <router-link
                to="./#wezudzial"
                @click="remove(), handleTrackingFormButtonClickEvent()"
                class="menu__link"
                >Weź udział</router-link
            >
        </div>
        <div class="menu__item">
            <router-link to="./#zwyciezcy" @click="remove" class="menu__link"
                >Zwycięzcy</router-link
            >
        </div>
        <div class="menu__item">
            <router-link to="./#kontakt" @click="remove" class="menu__link"
                >Kontakt</router-link
            >
        </div>
    </nav>
</template>

<script>
export default {
    methods: {
        remove() {
            document
                .querySelector(".js-menu-toggle")
                .classList.remove("menu--active");
            document
                .querySelector(".js-hamburger")
                .classList.remove("hamburger--active");
        },
        handleTrackingFormButtonClickEvent() {
            this.$gtm.trackEvent({
                event: "formButtonClick",
            });
        },
    },
    computed: {
        loteryActive() {
            return this.$store.state.loteryActive;
        },
    },
};
</script>
