<template>
    <component :is="'script'" type="text/javascript" id="Cookiebot" src=https://consent.cookiebot.com/uc.js data-cbid="0c38b9fd-6184-485a-bfa5-51ad8decaf2c" data-blockingmode="auto"></component>
    <component :is="'script'" type="text/javascript">
        !function(w,p,e,v,n,t,s){w['WphTrackObject'] = n;
        w[n] = window[n] || function() {(w[n].queue=w[n].queue||[]).push(arguments)},
        w[n].l = 1 * new Date(), t=p.createElement(e), s=p.getElementsByTagName(e)[0],
        t.async=1;t.src=v;s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://pixel.wp.pl/w/WP-ADS-LLYWWOMQB/tr.js', 'wph');
        wph('init', 'WP-ADS-LLYWWOMQB', DODATKOWE_PARAMETRY);
    </component> 
    <router-view />
</template>

<script>
import { event } from "vue-gtag";
import axios from "axios";

export default {
    name: "App",
    components: {},
    created() {
        event("conversion", {
            allow_custom_scripts: true,
            send_to: "DC-9648695/invmedia/lacia00+standard",
        });
    },
    mounted() {
        axios
            .get(`${process.env.VUE_APP_API_URL}/application/allowed`)
            .then((response) => {
                if (!response.data.allow) {
                    this.$store.commit("turnOffLottery");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>
