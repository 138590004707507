import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "./scss/main.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue3-cookies";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { createGtm } from "@gtm-support/vue-gtm";
createApp(App)
    .use(VueCookies)
    .use(VueAxios, axios)
    .use(PerfectScrollbar)
    .use(store)
    .use(router)
    .use(
        createGtm({
            id: process.env.VUE_APP_GTM_ID,
        }),
    )
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
    .mount("#app");
