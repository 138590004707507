<template>
    <footer class="footer">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-24 order-2 order-sm-1 col-sm-auto">
                    <SiteLogo />
                </div>
                <div class="col-24 order-1 order-sm-2 col-sm-auto">
                    <FooterNavbar />
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import SiteLogo from "@/components/SiteLogo";
import FooterNavbar from "@/components/navbars/FooterNavbar";

export default {
    components: {
        SiteLogo,
        FooterNavbar,
    },
};
</script>
