<template>
    <section-layout class="section-howto" id="jakgrac">
        <div class="patch patch--home-2"></div>
        <div class="patch patch--mobile patch--mobile-4"></div>
        <div class="patch patch--mobile patch--mobile-5"></div>
        <div class="heading heading--h2 text-center">Jak grać</div>
        <div class="section-howto__wrapper">
            <div class="row">
                <div class="col-sm-4">
                    <div class="section-howto__item align-items-start">
                        <img
                            src="@/assets/opak.png"
                            class="d-none d-sm-block section-howto__item-img"
                        />
                        <img
                            src="@/assets/opak-mobile.png"
                            class="d-block d-sm-none order-2 order-sm-1 section-howto__item-img"
                        />
                        <div
                            class="section-howto__item-content section-howto__item-content--arrow-1 section-howto__item-content--absolute"
                        >
                            <div
                                class="heading section-howto__heading text-center"
                            >
                                1
                            </div>
                            <p
                                class="section-howto__paragraph section-howto__paragraph--big section-howto__paragraph--big-1 text-center"
                            >
                                Kup mleko UHT Łaciate w&nbsp;kartonowym
                                opakowaniu i&nbsp;zachowaj paragon,
                                e&#x2011;paragon lub fakturę imienną oraz
                                wycięty kod z&nbsp;opakowania
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div
                        class="section-howto__item section-howto__item--item-2"
                    >
                        <img
                            src="@/assets/komp.png"
                            class="d-none d-sm-block section-howto__item-img"
                        />
                        <img
                            src="@/assets/komp-mobile.png"
                            class="d-block d-sm-none section-howto__item-img"
                        />
                        <div
                            class="section-howto__item-content section-howto__item-content--arrow-2 mt-4 mt-sm-0"
                        >
                            <div
                                class="heading section-howto__heading text-center"
                            >
                                2
                            </div>
                            <p
                                class="section-howto__paragraph section-howto__paragraph--big text-center"
                            >
                                Wypełnij formularz zgłoszeniowy
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div
                        class="section-howto__item section-howto__item--item-3"
                    >
                        <img
                            src="@/assets/nagrody-single.png"
                            class="d-none d-sm-block section-howto__item-img"
                        />
                        <img
                            src="@/assets/nagrody-single-mobile.png"
                            class="d-block d-sm-none order-2 order-sm-1 section-howto__item-img"
                        />
                        <div
                            class="section-howto__item-content section-howto__item-content--arrow-3 mt-4 mt-sm-0"
                        >
                            <div
                                class="heading section-howto__heading text-center"
                            >
                                3
                            </div>
                            <p
                                class="section-howto__paragraph section-howto__paragraph--big text-center"
                            >
                                Czekaj na najbliższe losowanie nagród
                                <br />i&nbsp;sprawdzaj wyniki
                                <br />w&nbsp;części „Zwycięzcy”
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row section-howto__row-patch">
                    <div
                        class="col-sm-4 section-howto__col-patch section-howto__col-patch--rule-1"
                    >
                        <p
                            class="section-howto__paragraph section-howto__paragraph--white section-howto__paragraph--absolute-1 text-center"
                        >
                            Zakupu i zgłoszenia<br />
                            do loterii należy<br />
                            dokonać od 2.04<br />
                            do 27.05.2024 roku.
                        </p>
                    </div>
                    <div
                        class="col-sm-4 section-howto__col-patch section-howto__col-patch--rule-2"
                    >
                        <p
                            class="section-howto__paragraph section-howto__paragraph--white section-howto__paragraph--absolute-2 text-center"
                        >
                            Jeden dowód zakupu (paragon, e-paragon albo faktura
                            imienna) może zostać zarejestrowany tylko jeden raz.
                        </p>
                    </div>
                    <div
                        class="col-sm-4 section-howto__col-patch section-howto__col-patch--rule-3"
                    >
                        <p
                            class="section-howto__paragraph section-howto__paragraph--white section-howto__paragraph--absolute-3 text-center"
                        >
                            Jeden dowód zakupu uprawnia do zarejestrowania
                            w&nbsp;ramach jednego zgłoszenia maksymalnie
                            <br />12 kodów promocyjnych.
                        </p>
                        <div class="patch patch--home-3"></div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <a
                                href="/Regulamin loterii_Łaciate_FINAL.pdf"
                                target="_blank"
                                class="btn button button--blue section-howto__button"
                                >Regulamin</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
export default {
    components: {
        SectionLayout,
    },
};
</script>
