<template>
    <div>
        <div v-if="typeof filled == 'string'">
            <div class="container my-5">
                <div class="heading heading--h2 text-center my-5">
                    {{ filled }}
                </div>
            </div>
        </div>
        <div v-if="filled && typeof filled == 'boolean'">
            <div class="container my-5">
                <div class="heading heading--h2 text-center my-5">
                    Formularz został już wcześniej wypełniony
                </div>
            </div>
        </div>
        <div v-if="!filled">
            <div class="container my-5">
                <div class="heading heading--h2 text-center my-5">
                    Wypełnij formularz aby odebrać nagrodę
                </div>
            </div>
            <form form id="" action="" @submit.prevent="sendForm" class="form">
                <div class="row">
                    <div class="col-6">
                        <div class="form__group">
                            <span class="form__span section-contact__span"
                                >Imię</span
                            >
                            <input
                                class="form__input form__input--border-black"
                                placeholder="Imię i Nazwisko"
                                v-model="form.first_name"
                            />
                            <div
                                class="form__error"
                                v-if="errors['first_name']"
                            >
                                {{ errors["first_name"][0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form__group">
                            <span class="form__span section-contact__span"
                                >Nazwisko</span
                            >
                            <input
                                class="form__input form__input--border-black"
                                placeholder="Imię i Nazwisko"
                                v-model="form.last_name"
                            />
                            <div class="form__error" v-if="errors['last_name']">
                                {{ errors["last_name"][0] }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="awardType == 0 || awardType == 1">
                    <div class="row">
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Rodzaj dokumentu</span
                                >
                                <select
                                    class="form__input form__input--border-black"
                                    v-model="form.id_card_type"
                                >
                                    <option value="dowód">Dowód</option>
                                    <option value="paszport">Paszport</option>
                                </select>
                                <div
                                    class="form__error"
                                    v-if="errors['id_card_type']"
                                >
                                    {{ errors["id_card_type"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Numer dokumentu</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Numer dokumentu"
                                    v-model="form.id_card_number"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['id_card_number']"
                                >
                                    {{ errors["id_card_number"][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"></div>
                    <div class="row">
                        <div class="col-6" v-if="!noPesel">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >PESEL</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="PESEL"
                                    v-model="form.pesel"
                                    pattern="\d*"
                                    maxlength="11"
                                    @keypress="isNumber($event)"
                                />
                                <div class="form__error" v-if="errors['pesel']">
                                    {{ errors["pesel"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Obywatelstwo</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Obywatelstwo"
                                    v-model="form.citizenship"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['citizenship']"
                                >
                                    {{ errors["citizenship"][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Adres</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Adres"
                                    v-model="form.address"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['address']"
                                >
                                    {{ errors["address"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Kod pocztowy</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Kod pocztowy"
                                    v-model="form.zip"
                                />
                                <div class="form__error" v-if="errors['zip']">
                                    {{ errors["zip"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Miasto</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Miasto"
                                    v-model="form.city"
                                />
                                <div class="form__error" v-if="errors['city']">
                                    {{ errors["city"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Województwo</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Województwo"
                                    v-model="form.province"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['province']"
                                >
                                    {{ errors["province"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Kraj</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Kraj"
                                    v-model="form.country"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['country']"
                                >
                                    {{ errors["country"][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="noPesel">
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Data urodzenia</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Data urodzenia"
                                    v-model="form.date_of_birth"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['date_of_birth']"
                                >
                                    {{ errors["date_of_birth"][0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form__group">
                                <span class="form__span section-contact__span"
                                    >Kraj urodzenia</span
                                >
                                <input
                                    class="form__input form__input--border-black"
                                    placeholder="Kraj urodzenia"
                                    v-model="form.country_of_birth"
                                />
                                <div
                                    class="form__error"
                                    v-if="errors['country_of_birth']"
                                >
                                    {{ errors["country_of_birth"][0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-24">
                            <label
                                class="form__label form__label--checkbox mt-4"
                            >
                                <input
                                    class="form__input form__input--checkbox"
                                    type="checkbox"
                                    v-model="noPesel"
                                />
                                <span
                                    class="form__checkmark form__checkmark--black"
                                ></span>
                                <p>Nie posiadam nr PESEL</p>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form__group">
                            <span class="form__span section-contact__span"
                                >Nr konta bankowego</span
                            >
                            <input
                                class="form__input form__input--border-black"
                                placeholder="Nr konta bankowego"
                                v-model="form.bank_account"
                            />
                            <div
                                class="form__error"
                                v-if="errors['bank_account']"
                            >
                                {{ errors["bank_account"][0] }}
                            </div>
                        </div>
                    </div>
                </div>

                <p class="section-form__paragraph mt-4 mb-1 mt-sm-5 mb-sm-5">
                    <a href="/Kluzula informacyjne_Loteria na prostych zasadach.pdf" target="_blank">
                        Treść klauzuli informacyjnej dotyczącej przetwarzania danych
                        osobowych
                    </a >
                </p>

                <label
                    class="form__label form__label--checkbox mt-4"
                    v-for="contactTerm of contactTerms"
                    :key="contactTerm.id"
                >
                    <input
                        class="form__input form__input--checkbox"
                        type="checkbox"
                        v-model="contactTerm.checked"
                    />
                    <span
                        class="form__checkmark form__checkmark--black"
                        :class="{
                            'form__checkmark--error':
                                termsErrors.includes(contactTerm.id) &&
                                !contactTerm.checked,
                        }"
                    ></span>
                    <p v-if="!readMoreActivated[contactTerm.id]">
                        {{ contactTerm.description.slice(0, 195) }}
                        <span
                            v-if="
                                !readMoreActivated[contactTerm.id] &&
                                contactTerm.description.length - 1 > 195
                            "
                            class="form__checkmark-expand"
                            @click.prevent="checkmarkExpand([contactTerm.id])"
                            >[rozwiń]</span
                        >
                    </p>
                    <p v-if="readMoreActivated[contactTerm.id]">
                        {{ contactTerm.description }}
                        <span
                            class="form__checkmark-collapse"
                            v-if="readMoreActivated[contactTerm.id]"
                            @click.prevent="checkmarkCollapse([contactTerm.id])"
                            >[zwiń]</span
                        >
                    </p>
                </label>
                <div class="form__error-terms" v-if="termsErrors.length > 0">
                    Zaznaczone zgody są wymagane.
                </div>
                <div class="form__error-terms" v-if="errors.grecaptcha_token">
                    Błąd walidacji recaptcha.
                </div>

                <div class="text-end">
                    <button
                        type="submit"
                        class="btn button button--blue"
                        title="Wyślij"
                    >
                        Wyślij
                    </button>
                </div>
                <send-form-modal ref="modal">
                    <div>
                        <p>
                            Dziękujemy.
                            <br />Twoja wiadomość została wysłana.
                        </p>
                        <p>
                            <img
                                class="form__popup-content-image"
                                src="@/assets/laciate-aics.svg"
                            />
                        </p>
                    </div>
                </send-form-modal>
            </form>
        </div>
    </div>
</template>

<script>
import SendFormModal from "@/components/parts/SendFormModal";
import axios from "axios";

export default {
    components: {
        SendFormModal,
    },
    data() {
        return {
            applicationId: null,
            winner: {},
            filled: "Sprawdzamy Twoje zgłoszenie",
            // filled: 'sprawdzamy',
            noPesel: false,
            contactTerms: [
                {
                    id: "1",
                    description:
                        "Wyrażam zgodę na przetwarzanie przez Organizatora loterii promocyjnej „Loteria na prostych zasadach”, tj. GPD Agency Sp. z o.o. Sp. k. z siedzibą w Poznaniu (60-829) przy ul. Roosevelta 18, moich danych osobowych, podanych przeze mnie w powyższym formularzu, w celach związanych z organizacją i przeprowadzeniem ww. loterii promocyjnej, w szczególności w celu wydania nagród i rozpatrzenia ewentualnych reklamacji przez Organizatora. ",
                    required: true,
                    checked: false,
                },
            ],
            form: {
                name: "",
                has_pesel: true,
            },
            readMoreActivated: {},
            termsErrors: [],
            errors: [],
            awardType: null,
        };
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        termsValidate() {
            this.termsErrors = [];
            this.contactTerms.forEach((item) => {
                if (item.required && !item.checked) {
                    this.termsErrors.push(item.id);
                }
            });
        },
        getFilled() {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}/applications/${this.applicationId}/filled`,
                    this.form,
                )
                .then((response) => {
                    console.log(response.data);
                    this.filled = response.data.filled;
                    this.form.first_name = response.data.first_name;
                    this.form.last_name = response.data.last_name;
                    this.awardType = response.data.award_type;
                })
                .catch((error) => {
                    this.filled = error.response.data.message;
                });
        },
        checkmarkExpand(id) {
            this.readMoreActivated[id] = true;
        },
        checkmarkCollapse(id) {
            this.readMoreActivated[id] = false;
        },
        async sendForm() {
            const ref = this;
            this.termsValidate();

            if (this.termsErrors.length > 0) {
                return;
            }
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("form");
            axios
                .post(
                    `${process.env.VUE_APP_API_URL}/applications/${this.applicationId}`,
                    {
                        ...this.form,
                        grecaptcha_token: token,
                    },
                )
                .then(() => {
                    ref.$refs.modal.showModal = true;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(this.errors);
                    ref.errors = error.response.data.errors;
                });
        },
    },
    watch: {
        noPesel(newVal) {
            this.form.has_pesel = !newVal;
        },
        "form.bank_account": function (newVal) {
            this.form.bank_account = newVal
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
            if (newVal.length > 26) {
                this.form.bank_account = this.form.bank_account.slice(0, 26);
            }
        },
    },
    mounted() {
        this.applicationId = this.$route.params.id;
        setTimeout(() => {
            this.getFilled();
        }, 1000);
    },
    computed: {},
};
</script>
