<template>
    <section-layout class="section-products" id="produkty">
        <div class="patch patch--home-4"></div>
        <div class="heading heading--h2 text-center">Produkty promocyjne*</div>
        <div class="section-products__wrapper">
            <img src="@/assets/produkty.png" />
            <p class="section-products__paragraph text-center">
                * Loteria obejmuje mleka UHT Łaciate w promocyjnych lub
                standardowych kartonowych opakowaniach o pojemności 1,5 l, 1 l i
                500 ml, zawierających kod alfanumeryczny w górnej części
                opakowania. Pełna lista produktów objętych loterią znajduje się
                w regulaminie.
            </p>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
export default {
    components: {
        SectionLayout,
    },
};
</script>
