<template>
    <section-layout class="section-winners" id="zwyciezcy">
        <div class="heading heading--h2 text-center">Zwycięzcy</div>
        <div class="section-winners__wrapper">
            <div class="row align-items-stretch">
                <div class="col-sm-6">
                    <div
                        class="row gx-3 gx-sm-4 gy-3 mt-sm-4 mt-2 mt-sm-4 section-winners__draw"
                        v-for="lottery_type in lottery_types"
                        :key="lottery_type.type"
                    >
                        <div
                            class="mb-0 section-winners__rewards-heading section-winners__rewards-heading--dark"
                        >
                            {{ lottery_type.type_text }}
                        </div>
                        <div
                            class="col-6"
                            v-for="lotery in loteries.filter(
                                ({ type }) => type === lottery_type.type,
                            )"
                            :key="lotery.id"
                        >
                            <div
                                class="section-winners__draw-item"
                                @click="showWinner(lotery.id)"
                            >
                                <div class="section-winners__draw-heading">
                                    {{ lotery.name }}
                                </div>
                                <div class="section-winners__draw-paragraph">
                                    {{
                                        new Date(
                                            lotery.draw_date,
                                        ).toLocaleString("pl", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                        })
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div
                        class="section-winners__rewards"
                        :class="{ 'section-winners__rewards--error': error }"
                    >
                        <div class="patch patch--mobile patch--mobile-6"></div>
                        <div class="patch patch--mobile patch--mobile-7"></div>
                        <div class="section-winners__rewards-wrapper">
                            <div
                                class="section-winners__rewards-item"
                                :class="{
                                    'section-winners__rewards-item--error':
                                        error,
                                }"
                                v-if="error"
                            >
                                <div
                                    class="m-0 section-winners__rewards-heading"
                                >
                                    {{ error }}
                                </div>
                            </div>
                            <div
                                class="section-winners__rewards-item"
                                v-for="(award, indexAward) in awards"
                                :key="'awward_' + indexAward"
                            >
                                <div class="section-winners__rewards-heading">
                                    {{ award.award_type_text }}
                                </div>
                                <div
                                    v-for="(item, indexWinner) in winners"
                                    :key="'winner_' + indexWinner"
                                >
                                    <div
                                        v-if="
                                            award.award_type == item.award_type
                                        "
                                        class="section-winners__rewards-winner-item"
                                    >
                                        <div
                                            class="section-winners__rewards-winner"
                                        >
                                            {{ item.first_name }}
                                            {{
                                                item.last_name.substring(0, 1) +
                                                "."
                                            }}
                                        </div>
                                        <div
                                            class="section-winners__rewards-code"
                                        >
                                            {{ item.code }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>
<script>
import SectionLayout from "@/layouts/SectionLayout";
import axios from "axios";

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            lottery_types: [],
            loteries: [],
            winners: {},
            awards: [],
            error: "",
        };
    },
    methods: {
        getLoteries() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/lotteries`)
                .then((response) => {
                    this.loteries = response.data;
                    this.lottery_types = response.data
                        .filter(
                            (v, i, a) =>
                                a.findIndex((t) => t.type === v.type) === i,
                        )
                        .map((item) => ({
                            type: item.type,
                            type_text: item.type_text,
                        }))
                        .sort((a, b) => a.type - b.type);
                    this.showWinner(1);
                });
        },
        showWinner(id) {
            this.awards = [];
            this.winners = [];
            this.error = "";
            axios
                .get(`${process.env.VUE_APP_API_URL}/lotteries/${id}/winners`)
                .then((response) => {
                    if (response.data.length === 0) throw new Error();
                    this.awards = response.data
                        .filter(
                            (v, i, a) =>
                                a.findIndex(
                                    (t) => t.award_type === v.award_type,
                                ) === i,
                        )
                        .map((item) => {
                            return {
                                award_type: item.award_type,
                                award_type_text: item.award_type_text,
                            };
                        });
                    this.winners = response.data;
                })
                .catch(() => {
                    const lottery = this.loteries.find(
                        (lottery) => lottery.id === id,
                    );
                    if (!lottery) {
                        this.error = `Wystąpił problem podczas pobierania wyników losowania.`;
                        return;
                    }
                    const lotteryNo = new RegExp(/(?<lotteryNo>[0-9]+)/g).exec(
                        lottery.name,
                    )?.groups?.lotteryNo;
                    const lotteryName = lotteryNo
                        ? `nr ${lotteryNo}`
                        : `"${lottery.name}"`;
                    const endDate = new Date(
                        lottery.end_date,
                    ).toLocaleDateString("pl", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    });

                    // eslint-disable-next-line no-irregular-whitespace
                    this.error = `Wyniki Losowania ${lotteryName} zostaną opublikowane najpóźniej do ${endDate} roku.`;
                });
        },
    },
    created() {
        this.getLoteries();
    },
};
</script>

<style>
.section-winners__rewards.ps--active-y > .ps__rail-y {
    background-color: #eee;
    opacity: 0.6;
}

.section-winners__rewards .ps__rail-y .ps__thumb-y {
    width: 11px;
    background-color: #999;
}
</style>
