<template>
    <section-layout class="section-form" id="wezudzial">
        <div class="patch patch--home-5"></div>
        <div class="patch patch--home-6"></div>
        <div class="patch patch--home-7"></div>
        <svg class="section-form__bg-mobile">
            <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                <path
                    d="M0.998,0.043 C0.591,0.039,0.603,-0.036,-0.002,0.022 V1 H0.998 V0.043"
                ></path>
            </clipPath>
        </svg>
        <div class="text-center">
            <img
                src="@/assets/form-heading.svg"
                class="section-form__heading d-none d-sm-inline-block"
            />
            <img
                src="@/assets/form-heading-mobile.svg"
                class="d-inline-block d-sm-none"
            />
        </div>
        <div class="section-form__wrapper">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-11 col-md-9" style="width: 1190px">
                    <div class="section-form__form">
                        <div
                            class="d-none d-sm-block heading text-center heading--white heading--h3"
                        >
                            Formularz zgłoszeniowy
                        </div>
                        <LotteryForm />
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
import LotteryForm from "@/components/forms/LotteryForm";

export default {
    data() {
        return {};
    },
    components: {
        SectionLayout,
        LotteryForm,
    },
};
</script>
