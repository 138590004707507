<template>
    <section-layout class="section-banner">
        <Transition name="fade" appear>
            <div
                class="section-banner__modal-overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
        </Transition>
        <div class="section-banner__popup" v-if="!showModal">
            <img
                src="@/assets/video-button.svg"
                @click="showModal = true"
                class="section-banner__popup-button"
            />
        </div>

        <img
            class="section-banner__main-prize-info-patch"
            src="@/assets/patch-main-prize.svg"
        />

        <Transition name="fade" appear>
            <div class="section-banner__modal" v-if="showModal">
                <div class="video">
                    <iframe
                        class="video__iframe"
                        frameborder="0"
                        allowfullscreen="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="YouTube video player"
                        width="640"
                        height="360"
                        src="https://www.youtube.com/embed/Q09EwgyXG4s?enablejsapi=1&amp;origin=https%3A%2F%2Fmlekolaciate.pl%2F&amp;widgetid=11"
                        id="widget12"
                    ></iframe>
                </div>
                <div
                    class="section-banner__modal-icon-close"
                    @click="showModal = false"
                ></div>
            </div>
        </Transition>
        <img
            src="@/assets/logo-laciata-loteria.svg"
            class="section-banner__img"
        />
        <div class="row" v-if="loteryActive">
            <div class="col">
                <router-link
                    to="#wezudzial"
                    class="btn button section-banner__button"
                    @click="handleTrackingFormButtonClickEvent"
                    >Weź udział</router-link
                >
            </div>
        </div>
        <div v-if="!loteryActive" class="section-banner__end-text">
            <p>
                <b>27 maja 2024 roku</b> upłynął termin przyjmowania zgłoszeń.
                <br />
                Dziękujemy za udział w Loterii na prostych zasadach.
                <br /> <br/>
                Ostatnie losowania odbędą się w dniu <b>20 czerwca 2024 roku</b>,
                więc przypominamy o zachowaniu dowodów zakupu
                i fragmentów opakowań z kodem promocyjnym.
                <br /> <br/>
                <b>GRATULUJEMY ZWYCIĘZCOM!</b>
            </p>
        </div>
    </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        handleTrackingFormButtonClickEvent() {
            this.$gtm.trackEvent({
                event: "formButtonClick",
            });
        },
    },
    components: {
        SectionLayout,
    },
    computed: {
        loteryActive() {
            return this.$store.state.loteryActive;
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
