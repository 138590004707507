<template>
    <div class="form__popup">
        <Transition name="fade" appear>
            <div
                ref="modal"
                class="form__popup-content form__popup-content--fixed"
                v-if="showModal"
            >
                <div
                    class="form__popup-icon-close form__popup-icon-close--black"
                    @click="showModal = false"
                ></div>
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: "SendFormModal",
    data() {
        return {
            showModal: false,
        };
    },
};
</script>
